import React from 'react'
import Layout from '../components/layout/Layout'
import { Section } from '../pages/index'
import { Button } from '../components/elements'
import SEO from "@layout/SEO"

const ThankYou = props => {
  
    
    return (
        <>
        <SEO title="Thank you" description="Its exciting to think about where this potential new partnership could take us!" />

        <Layout>
            <Section className="justify-center flex-col text-center px-4">
                <i className="fa fa-envelope text-6xl my-4 text-primary"></i>
                <h2>Thank you for getting in touch.</h2>
                <h5>You'll be hearing from us soon.</h5>

                <Button linkTo="/" className="mx-auto block mt-8">Back to home</Button>
            </Section>
        </Layout>
        </>
    )
}

ThankYou.propTypes = {

}

export default ThankYou
